<template>
    <div class="all_main_wrap">
        <!-- 头部 -->
        <div class="all_header_box">
            <div class="sider_left">
                <span class="title">
                <router-link
                    class="crumbs_item"
                    tag="a"
                    to="/admin/Activity/ActivityManager"
                >
                    活动管理 /</router-link
                >
                <span class="crumbs_item crumbs_last">
                    编辑活动</span
                >
                </span>
            </div>
        </div>
        <a-form-model style="margin-top: 30px;" ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 3 }">
            <a-form-model-item label="活动名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入活动名称" class="wide-input" />
            </a-form-model-item>
            <a-form-model-item label="活动封面" prop="picture">
                <ImageUpload bucket="template/activity/"  :value="form.picture" @input="handlepicture" :isCropp="true"></ImageUpload>
            </a-form-model-item>
            <a-form-model-item label="分享图" prop="sharePicture">
                <ImageUpload bucket="template/activity/"  :value="form.sharePicture" @input="handlesharePicture" :autoCropWidth='200'  :autoCropHeight='200'   :isCropp="true"  fileScale="1:1"
                    filePixel="400×400"></ImageUpload>
            </a-form-model-item>
            <a-form-model-item label="举办城市" prop="city">
                <a-input v-model="form.city" class="wide-input" type="textarea" :min="3" placeholder="请填写举办城市，多个城市用英文逗号隔开" :auto-size="{ minRows: 2 }" />
            </a-form-model-item>
            <a-form-model-item label="主办方" prop="sponsor">
                <a-input v-model="form.sponsor" placeholder="请输入主办方名称" class="wide-input" />
            </a-form-model-item>
            <a-form-model-item label="活动场次" prop="activitySessions">
                <a-button type="primary" @click="visibleShow = true">添加场次+</a-button>
                <a-table
                    class="table-template"
                    :rowKey="(item, index) => index"
                    :columns="columns"
                    :data-source="form.activitySessions"
                    :pagination="false">
                    <template slot="index" slot-scope="item, row, index">
                        {{ index + 1  }}
                    </template>
                    <!-- 活动时间 -->
                    <template slot="sessioActiveTimes" slot-scope="item">
                        {{ item.activityStartDate }} ~ {{ item.activityEndDate }}
                    </template>
                    <template slot="sessiosignupTimes" slot-scope="item">
                        {{ item.signupStartDate }} ~ {{ item.signupEndDate }}
                    </template>
                    <template slot="address" slot-scope="item">
                        {{ item.city }} - {{ item.address }}
                    </template>
                    <template slot="sort" slot-scope="item,row">
                        <a-input-number id="inputNumber" v-model="row.sort" :min="1" @blur="onSortChange(row)" />
                    </template>
                    <template slot="operation" slot-scope="item,row,index">
                        <span class="btn_router_link">
                            <a @click="onSessionEdit(item,index)">编辑</a>
                            <span>|</span>
                            <a-popconfirm title="确认删除该场次吗？" @confirm="onSessionDel(item,index)" ok-text="是" cancel-text="否">
                                <a href="#" >删除</a>
                            </a-popconfirm>
                        </span>
                    </template>
                </a-table>
            </a-form-model-item>
            <a-form-model-item label="活动简介" prop="synopsis">
                <a-input class="activityDetails-input" v-model="form.synopsis" type="textarea" :min="3" placeholder="请输入活动简介" :auto-size="{ minRows: 2 }" />
            </a-form-model-item>
            <a-form-model-item label="活动详情" prop="activityDetails">
                <UE
                    class="editor"
                    editorId="introduceEditor1"
                    :initContent="form.activityDetails"
                    @input="getContent"
                />
            </a-form-model-item>
            <a-form-model-item label="活动时间" prop="activityStartDate">
                <a-range-picker class="wide-input" :placeholder="['活动开始时间', '活动结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectActiveTime" v-model="activeTimes"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="报名时间" prop="signupStartDate">
                <a-range-picker :placeholder="['报名开始时间', '报名结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectSignupTime" v-model="signupTimes"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="注意事项" prop="precautions">
                <a-input v-model="form.precautions" class="wide-input" type="textarea" :min="3" placeholder="请填写活动注意事项（参加活动所需携带物品）" :auto-size="{ minRows: 3 }" />
            </a-form-model-item>
            <a-form-model-item label="活动附件" prop="attachments">
                <a-upload
                    name="videoFileId"
                    :beforeUpload="beforeUpload"
                    :showUploadList="false"
                    :customRequest="customRequest"
                >
                    <a-button ref="btn">
                    <template v-if="uploadLoading">
                        <a-icon type="loading" />
                        <span>上传中（{{speed}}Mb/s）</span>
                    </template>
                    <template v-else>
                        <a-icon type="upload" />
                        <span>选择附件</span>
                    </template>
                    </a-button>
                </a-upload>
                <div v-if="uploadLoading">
                    <a-progress :strokeWidth=3 :percent="progress" />
                </div>
                <div v-if="form.attachmentsName">
                    附件名称：{{form.attachmentsName}}
                </div>
            </a-form-model-item>
            <a-form-model-item label="附件列表" v-show="fileList.length">
                <p style="cursor: pointer;" v-for="(item, index) in fileList" :key="item.key" @click="openFile(item)">{{ item}}<a-icon style="margin-left:20px" @click.stop="onFileDel(index)" type="close-circle"/></p>
            </a-form-model-item>
            
            <a-form-model-item label="是否需要审核" prop="isAudit">
                <a-radio-group v-model="form.isAudit">
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">否</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="用户可上传附件" prop="attachmentsUpload">
                <a-radio-group v-model="form.attachmentsUpload">
                    <a-radio :value="1">是</a-radio>
                    <a-radio :value="0">否</a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="用户上传附件说明" v-show="form.attachmentsUpload==1" prop="attachmentsDesc">
                <a-input v-model="form.attachmentsDesc" class="wide-input" type="textarea" :min="3" placeholder="请输入上传附件说明" :auto-size="{ minRows: 2 }" />
            </a-form-model-item>
            
            <a-form-model-item label="是否前端展示" prop="isShow">
                <a-radio-group v-model="form.isShow">
                    <a-radio :value="1">展示</a-radio>
                    <a-radio :value="0">不展示</a-radio>
                </a-radio-group>
            </a-form-model-item>
        </a-form-model>
        <div slot="footer" class="dialog-footer">
            <a-button class="btn" @click="$router.go(-1)">返回</a-button>
            <a-button class="btn" type="primary" :loading="submitLoad" @click="onSubmitForm">保存</a-button>
            <a-button class="btn" v-if="acticityId" type="primary" :loading="submitLoad" @click="onPublishedEdit">{{form.published ? '下架':'上架'}}</a-button>
        </div>
        <a-modal
            :closable="false"
            :visible="visibleShow"
            :confirm-loading="confirmLoading"
            title="场次维护"
            @ok="onSessionAdd"
            @cancel="visibleShow = false"
        >
            <a-form-model ref="sessionForm" :model="sessionForm" :rules="sessionRules">
                <a-form-model-item label="场次名称" prop="sessionName">
                    <a-input class="max-width" v-model="sessionForm.sessionName" placeholder="请输入场次名称" />
                </a-form-model-item>
                <a-form-model-item label="举办城市" prop="city">
                    <a-input class="max-width" v-model="sessionForm.city" placeholder="请输入举办城市" />
                </a-form-model-item>
                <a-form-model-item label="详细地址" prop="address">
                    <a-input class="max-width" v-model="sessionForm.address" placeholder="请输入详细地址" />
                </a-form-model-item>
                <a-form-model-item label="活动时间" prop="activityStartDate">
                    <a-range-picker :placeholder="['活动开始时间', '活动结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectSessionActiveTime" v-model="sessioActiveTimes"></a-range-picker>
                </a-form-model-item>
                <a-form-model-item label="报名时间" prop="signupStartDate">
                    <a-range-picker :placeholder="['活动开始时间', '活动结束时间']" valueFormat="YYYY-MM-DD HH:mm:ss" showTime @change="onSelectSessionSignupTime" v-model="sessiosignupTimes"></a-range-picker>
                </a-form-model-item>
                <a-form-model-item label="报名人数" prop="number">
                    <a-input-number class="max-width" :min=1 v-model="sessionForm.number" placeholder="请输入报名人数" />
                </a-form-model-item>
                <a-form-model-item label="主讲老师" prop="lecturer">
                    <a-input class="max-width" v-model="sessionForm.lecturer" placeholder="请输入主讲老师" />
                </a-form-model-item>
                <!-- <a-form-model-item label="联系方式" prop="contact">
                    <a-input class="max-width" v-model="sessionForm.contact" placeholder="请输入联系方式" />
                </a-form-model-item> -->
                <a-form-model-item label="客服二维码" prop="customerService">
                    <ImageUpload bucket="template/activity/" :value="sessionForm.customerService" @input="handlecustomerService" :isCropp="false"></ImageUpload>
                </a-form-model-item>
                <a-form-model-item label="报名提示" prop="taps">
                    <a-input class="max-width" type="textarea" v-model="sessionForm.taps" :auto-size="{ minRows: 3 }" placeholder="请输入报名提示" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import UE from "@/components/UE.vue";
import { openFile } from "@/unit/fun.js";
const columns = [
{
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "场次名称",
    align: "center",
    dataIndex: "sessionName",
  },
  {
    title: "活动地点",
    align: "center",
    scopedSlots: {
      customRender: "address",
    },
  },
  {
    title: "活动时间",
    align: "center",
    scopedSlots: {
      customRender: "sessioActiveTimes",
    },
  },
  {
    title: "报名时间",
    align: "center",
    scopedSlots: {
      customRender: "sessiosignupTimes",
    },
  },
  {
    title: "报名人数限制",
    align: "center",
    dataIndex: "number",
  },
  {
    title: "排序",
    align: "center",
    scopedSlots: {
      customRender: "sort",
    },
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
    // 可用组件的哈希表
    components: { UE },
    name: "Activity",
    data() {
        return {
            columns,
            // 遮罩层
            loading: false,
            // 提交的加载
            submitLoad: false,
            // 活动配置表格数据
            activityList: [],
            // 弹出层标题
            title: "",
            firstShow: true,
            // 是否显示弹出层
            open: false,
            // 活动时间
            activeTimes: [],
            // 报名时间
            signupTimes: [],
            //附件上传load
            uploadLoading: false, 
            // 文件上传进度
            progress:0, 
            // 传输速度
            speed:0,
            // 附件
            fileList: [],
            // 场次维护弹窗
            visibleShow: false,
            // 场次加载
            confirmLoading: false,
            // 场次表单
            sessionForm: {
                sessionName: '', // 场次名称
                city: '', // 举办城市
                address: '', // 详细地址
                activityStartDate: '', // 活动开始时间
                activityEndDate: '', // 活动结束时间
                signupStartDate: '', // 报名开始时间
                signupEndDate: '', // 报名结束时间
                number: '', // 报名人数限制
                lecturer: '', // 主讲老师
                contact: '', // 联系方式
                customerService: '', // 客服二维码
                taps: '', // 报名提示
                sort:1, // 展示顺序
            },
            sessionIndex: undefined,
            // 活动时间
            sessioActiveTimes: [],
            // 报名时间
            sessiosignupTimes: [],
            // 场次表单校验
            sessionRules: {
                sessionName: [
                    { required: true, trigger: "blur", message: "请输入场次名称" },
                ],
                city: [
                    { required: true, trigger: "blur", message: "请输入举办城市" },
                ],
                address: [
                    { required: true, trigger: "blur", message: "请输入详细地址" },
                ],
                activityStartDate: [
                    { required: true, trigger: "blur", message: "请选择活动时间" },
                ],
                signupStartDate: [
                    { required: true, trigger: "blur", message: "请选择报名时间" },
                ],
                number: [
                    { required: true, trigger: "blur", message: "请输入报名人数" },
                ],
            },
            // 表单参数
            form: {
                id: undefined,
                name: '', // 活动名称
                picture: '',// 封面
                sharePicture: '', // 分享图
                synopsis: undefined, // 活动简介
                activityDetails: '', // 活动详情
                activitySessions: [], // 场次列表
                activityStartDate: undefined, // 活动开始时间
                activityEndDate: undefined, // 活动结束时间
                signupStartDate: undefined, // 报名开始时间
                signupEndDate: undefined, // 报名结束时间
                precautions: '', // 活动须知
                city: '', // 举办城市，多个城市用,隔开
                sponsor: '', // 主办方
                isAudit: 1, // 是否需要审核 1是 0否
                attachmentsUpload:1, // 用户是否可以上传附件 0 否 1 是
                attachmentsName:'', // 附件名称
                attachments:'', // 附件，  多个附件用,隔开
                attachmentsDesc:'', // 附件说明
                isShow: 1, // 是否展示 1.是 0.否
            },
            // 表单校验
            rules: {
                name: [
                    { required: true, trigger: "blur", message: "请填写活动名称" },
                ],
                picture: [
                    { required: true, trigger: "blur", message: "请上传活动封面图" },
                ],
                sharePicture: [
                    { required: true, trigger: "blur", message: "请上传活动分享图" },
                ],
                city: [
                    { required: true, trigger: "blur", message: "请填写举办城市" },
                ],
                activitySessions: [
                    { required: true, trigger: "blur", message: "请配置活动场次" },
                ],
                activitySessions: [
                    { required: true, trigger: "blur", message: "请配置活动场次" },
                ],
                activitySessions: [
                    { required: true, trigger: "blur", message: "请配置活动场次" },
                ],
                synopsis: [
                    { required: true, trigger: "blur", message: "请填写活动简介" },
                ],
                activityDetails: [
                    { required: true, trigger: "blur", message: "请填写活动详情" },
                ],
                activityStartDate: [
                    { required: true, trigger: "blur", message: "请选择活动时间" },
                ],
                signupStartDate: [
                    { required: true, trigger: "blur", message: "请选择报名时间" },
                ],
            },
            // 上传表单信息对象
            submitFormData: {
                province: undefined,
                provinceCode: undefined,
                cityCode: undefined,
                area: undefined,
                areaCode: undefined,
                address: undefined, // 详细地址
                number: undefined, // 报名人数
                signupPrice: undefined, // 活动费用
                giftId: undefined, // 奖品id
                giftRemain: undefined, // 奖品剩余数量
                remindTime: undefined, // 活动开始提醒时间
                contact: undefined, // 报名联系方式
                picture: undefined, // 活动封面
                sharePicture: undefined, // 分享图
                activityStatus: 1, // 状态 1.暂存 2.活动未开始 3.活动中 4.活动结束   默认写1
            },
            acticityId: undefined,
        };
    },
    created() {
        this.acticityId = this.$route.query.acticityId;
        if (this.acticityId) {
            this.getDetail()
        }
    },
    methods: {
        // 获取活动封面图
        handlepicture(url){
            this.form.picture = url
            this.$refs.ruleForm.clearValidate(['picture']);
        },
        // 获取活动分享图
        handlesharePicture(url){
            this.form.sharePicture = url
            this.$refs.ruleForm.clearValidate(['sharePicture']);
        },
        // 获取客服二维码
        handlecustomerService(url){
            this.sessionForm.customerService = url
            // this.$refs.sessionForm.clearValidate(['customerService']);
        },
        // 活动详情
        getContent(e) {
            this.$set(this.form, "activityDetails", e);
            this.$refs.ruleForm.clearValidate(['activityDetails']);
        },
        // 活动开始、结束时间
        onSelectActiveTime(date, dateString) {
            this.form.activityStartDate = dateString[0]; // 开始日期
            this.form.activityEndDate = dateString[1]; // 结束日期
            this.$refs.ruleForm.clearValidate(['activityStartDate']);
        },
        // 报名开始、结束时间
        onSelectSignupTime(date, dateString) {
            this.form.signupStartDate = dateString[0]; // 开始日期
            this.form.signupEndDate = dateString[1]; // 结束日期
            this.$refs.ruleForm.clearValidate(['signupStartDate']);
        },
        // 场次活动开始、结束时间
        onSelectSessionActiveTime(date, dateString) {
            this.sessionForm.activityStartDate = dateString[0]; // 开始日期
            this.sessionForm.activityEndDate = dateString[1]; // 结束日期
            this.$refs.sessionForm.clearValidate(['activityStartDate']);
        },
        // 场次报名开始、结束时间
        onSelectSessionSignupTime(date, dateString) {
            this.sessionForm.signupStartDate = dateString[0]; // 开始日期
            this.sessionForm.signupEndDate = dateString[1]; // 结束日期
            this.$refs.sessionForm.clearValidate(['signupStartDate']);
        },
        // 试题附件上传前钩子
        beforeUpload(file) {
            var reg = /\.(pdf|doc|docx|txt|jpg|png|gif|jpeg|mp4|xlsx|xls)(\?.*)?$/;
            if (!reg.test(file.name)) {
                this.$message.warning(
                this.$regular.fileReg.msg +
                    "，仅支持：pdf、doc、docx、txt、jpg、png、gif、jpeg、mp4、xlsx、xls"
                );
                return false;
            }

            if (this.uploadLoading) {
                this.$message.warning("附件正在上传中，请稍后重试");
                return false;
            }
        },
        // 附件上传 - 单文件
        customRequest(fileData) {
            this.progress = 0
            this.speed = 0
            this.uploadLoading = true

            const bucket = 'template/activity/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
            // 腾讯云对象存储上传文件
            this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
                // 上传完成
                if (url) {
                    this.fileList = [url]
                    this.form.attachmentsName = fileName
                    this.$message.success("上传成功");
                    setTimeout(() => {
                        this.uploadLoading = false
                    }, 400);
                } else {
                    this.$message.error("上传失败");
                    this.uploadLoading = false
                }
            });
        },
        // 文件上传更新进度和单文件上传初始化
        fileProgressCallback(progress, speed, name) {
            /*
            * progress 进度
            * speed 传输速度
            * name 文件名称
            * */
            this.progress = progress
            this.speed = speed
        },
        // 已上传附件删除
        onFileDel(index) {
            this.form.attachmentsName = ''
            this.form.attachmentsDesc = ''
            this.fileList.splice(index, 1);
        },
        // 打开文件
        openFile(url) {
            openFile(url);
        },
        // 场次添加
        onSessionAdd(){
            this.$refs.sessionForm.validate((valid) => {
                if (valid) {
                        if(this.acticityId){
                            this.confirmLoading = true;
                            this.sessionForm.activityId = this.acticityId
                            this.$ajax({
                                url: "/hxclass-management/activitySession/audit",
                                method: "POST",
                                params: this.sessionForm,
                            }).then((res) => {
                                if (res.code == 200 && res.success) {
                                    this.getDetail()
                                } else {
                                    this.$message.warning(res.message);
                                    return
                                }
                                this.confirmLoading = false;
                            });
                        } else {
                            if(this.sessionIndex !== undefined){
                                this.form.activitySessions[this.sessionIndex] = this.sessionForm
                            } else {
                                this.form.activitySessions.push(this.sessionForm)
                            }
                            this.$refs.ruleForm.clearValidate(['activitySessions']);
                        }
                        
                        this.$message.success('操作成功');
                        this.visibleShow = false
                    }
                });
        },
        // 场次排序修改
        onSortChange(item) {
            if(!item.sessionId){
                return
            }
            let params = {
                sessionId: item.sessionId,
                sort: item.sort,
            }
            this.$ajax({
                url: "/hxclass-management/activitySession/audit",
                method: "post",
                params: params
            })
            .then((res) => {
                if (res.code == 200 && res.success) {
                    this.$message.success("操作成功");
                    this.getDetail();
                }
            });
        },
        onSessionEdit(e,index){
            if(!this.acticityId){
                this.sessionIndex = index
            }
            this.sessioActiveTimes = [e.activityStartDate,e.activityEndDate]
            this.sessiosignupTimes = [e.signupStartDate,e.signupEndDate]
            this.sessionForm = JSON.parse(JSON.stringify(e))
            if(this.firstShow){
                this.sessionForm.customerService = ''
            }
            this.visibleShow = true
            setTimeout(() => {
                this.sessionForm.customerService = e.customerService
                this.firstShow = false
            },200)
        },
        // 场次删除
        onSessionDel(e,index){
            if(e.sessionId){
                this.$ajax({
                    url: "/hxclass-management/activitySession/delete?id=" + e.sessionId,
                    method: "DELETE",
                }).then((res) => {
                    if (res.code == 200 && res.success) {
                        this.form.activitySessions = this.form.activitySessions.filter(item => item.sessionId !== e.sessionId)
                    } else {
                        this.$message.warning(res.message);
                    }
                    this.submitLoad = false;
                });
            } else {
                this.form.activitySessions.splice(index,1)
            }
        },
        // 查询活动详情
        getDetail() {
            this.$ajax({
                method: "get",
                url: "/hxclass-management/activity/manage/detail?id=" + this.acticityId,
            }).then((res) => {
                if (res.code == 200 && res.success) {
                    this.form = res.data
                    if(this.form.attachments){
                        this.fileList = this.form.attachments.split(",")
                    }
                    this.activeTimes = [this.form.activityStartDate,this.form.activityEndDate]
                    this.signupTimes = [this.form.signupStartDate,this.form.signupEndDate]
                }
            });
        },
        // 表单提交
        onSubmitForm(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.submitLoad = true;
                    if(this.fileList.length){
                        this.form.attachments = this.fileList.toString()
                    } else {
                        this.form.attachments = ''
                    }
                    this.$ajax({
                        url: "/hxclass-management/activity/manage/audit",
                        method: "POST",
                        params: this.form,
                    }).then((res) => {
                        if (res.code == 200 && res.success) {
                            this.$message.success(res.message);
                            this.$router.go(-1);
                        } else {
                            this.$message.warning(res.message);
                        }
                        this.submitLoad = false;
                    });
                } else {
                    // console.log("error submit!!");
                    return false;
                }
            });
        },
        onPublishedEdit(){
            let _this = this;
            _this.$confirm({
                title: "确定" + (_this.form.published ? '下架':'上架') + "该活动吗?",
                okText: "确认",
                onOk() {
                    _this.submitLoad = true;
                    _this.$ajax({
                        url: "/hxclass-management/activity/manage/audit",
                        method: "POST",
                        params: {
                            id: _this.acticityId,
                            published: _this.form.published?0:1,
                        },
                    }).then((res) => {
                        if (res.code == 200 && res.success) {
                            _this.$message.success(res.message);
                            _this.$router.go(-1);
                        } else {
                            _this.$message.warning(res.message);
                        }
                        _this.submitLoad = false;
                    });
                },
                onCancel() {},
            });
        }
    },
    watch: {
        visibleShow(){
            if(!this.visibleShow){
                this.sessioActiveTimes = []
                this.sessiosignupTimes = []
                this.sessionForm = {
                    sessionName: '', // 场次名称
                    city: '', // 举办城市
                    address: '', // 详细地址
                    activityStartDate: '', // 活动开始时间
                    activityEndDate: '', // 活动结束时间
                    signupStartDate: '', // 报名开始时间
                    signupEndDate: '', // 报名结束时间
                    number: '', // 报名人数限制
                    lecturer: '', // 主讲老师
                    contact: '', // 联系方式
                    customerService: '', // 客服二维码
                    taps: '', // 报名提示
                    sort:1, // 展示顺序
                }
            }
        }
    }

};
</script>


<style scoped lang="less" scoped>
/deep/ .ant-form-item {
    display: flex;
}

/deep/ .ant-form-item-label {
    display: inline-block;
    
}

/deep/ .a-radio__label {
    padding-left: 3px;
}

.wide-input {
  width: 400px;
}
.max-width{
    width: 350px;
}


.activityDetails-input {
    width: 570px;

    /deep/ .ant-input {
        width: 100%;
    }
}

.dialog-footer{
    .btn{
        margin-right: 20px;
    }
}
</style>